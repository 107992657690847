import React from "react";

function lightMode() {
  document.body.style.backgroundColor = "white";
  document.body.style.color = "black";

  let toggleContainer = document.querySelector(".toggleContainer");
  toggleContainer.style.border = "3px solid black";
}

function darkMode() {
  document.body.style.backgroundColor = "black";
  document.body.style.color = "white";

  let toggleContainer = document.querySelector(".toggleContainer");
  toggleContainer.style.border = "3px solid white";
}

const DarkModeSwitch = () => {
  return (
    <div className="toggleContainer">
      <div className="toggleButton lightButton" onClick={lightMode}></div>
      <div className="toggleButton darkButton" onClick={darkMode}></div>
    </div>
  );
};

export default DarkModeSwitch;
