import "./App.css";
import DarkModeSwitch from "./DarkModeSwitch";

function App() {
  return (
    <>
      <h1>Berkeley, CA</h1>
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1654328359/berkeley_ql06vk.jpg"
        alt="Berkeley and some of the surrounding Bay Area, satellite view from Google"
      />
      {/* <p>37.87310178178725, -122.3214383775146</p> */}
      <p>37.873118839460204, -122.32143528625117</p>
      <p>One of the centers of the world</p>
      <DarkModeSwitch />
    </>
  );
}

export default App;
